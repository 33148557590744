.color-default {
  color: #293D82;
}

.bg-default {
  background-color: #293D82;
}

.border-bottom-default {
  border-bottom: 5px solid #293D82;
}

.borderCard {
  border-bottom: 3px solid #293D82;
}

.scrollable {
  height: 200px;
  overflow-y: scroll;

}



.child2 {
  /*position: absolute;*/
  /*z-index: 1;*/
  /*width: 100%;*/

}

.child3 {
  position: absolute;
  z-index: 1;
  width: 100%;

}